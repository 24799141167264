import Layout from "../components/layout-main";
import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx } from "theme-ui";

const PrivacyPage = ({ data, location }) => {
  return (
    <Layout location={location} pageTitle="Privacy Policy" hideSubscribe={true}>
      <section className="aw-accent">
        <div className="aw-content">
          <h1>Privacy Policy</h1>
          <p>
            <i>
              Last Updated:{" "}
              {new Date(data.markdownRemark.frontmatter.date)
                .toISOString()
                .slice(0, 10)}
            </i>
          </p>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPage;

export const pageQuery = graphql`
  query PrivacyPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/privacy.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }
`;
